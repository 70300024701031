import React from "react";
import { Helmet } from "react-helmet";
import { Navbar } from "../../components/navbar/navbar";
import ArticlePreview from "../../components/articlePreview/articlePreview";
import { getRandomImage } from "../../utils/utils";

export const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>Home - Lega Pauper Livorno</title>
        <meta name="description" content="Benvenuti nella Lega Pauper Livorno! Scopri eventi, aggiornamenti e notizie entusiasmanti." />
        <meta name="keywords" content="Lega Pauper, eventi, notizie, aggiornamenti" />
      </Helmet>
      <Navbar item={"home"} />
      <div className="p-5 md:p-20 bg-[#f6f6f6]">
        <h1 className="text-4xl font-extrabold">Benvenut* nella ciurma!</h1>
        <p className="mt-4 text-lg">
          Salpa con noi per un'avventura nel mondo della Lega Pauper Livorno! Qui troverai tutte le novità, gli aggiornamenti e le esplorazioni più recenti. Preparati a navigare tra eventi e scoperte esclusive.
        </p>
        <div className="mt-10 bg-white p-10 rounded-xl border">
          <h2 className="text-2xl font-bold">Le Ultime Notizie</h2>
          <p className="mt-2 text-lg">
            Non perdere le ultime novità della <span className="font-extrabold underline">Lega Pauper Livorno</span>. 
            Scopri le ultime tendenze, eventi e aggiornamenti che stanno scuotendo il nostro mondo!
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5 mt-6">
          <ArticlePreview
              link="/contacts"
              title="Chi Siamo?"
              descr="La nostra ciurma che porta avanti il galeone."
              image={getRandomImage()}
              tags={["Ciurma", "Contatti"]}
              date="24 Set 2024"
            />
            <ArticlePreview
              link="/league"
              title="Esplora tutte le leghe!"
              descr="Un approfondimento sulle leghe più entusiasmanti e le strategie vincenti."
              image={getRandomImage()}
              tags={["Leghe"]}
              date="02 Set 2024"
            />
            <ArticlePreview
              link="/event/1/U2FsdGVkX1%2BNnzuQClsJ8lvLSuBAUmxdVZJ7Nh81WBZaRvX9uqicdL%2BFN8e53IB8"
              title="Lega Pauper - Autumn Season"
              descr="Diamo inizio alla seconda stagione delle Lega Pauper Livorno."
              image={getRandomImage()}
              tags={["Lega", "2° Stagione"]}
              date="02 Set 2024"
            />
             <ArticlePreview
              link="/event/1/U2FsdGVkX19waJHO%2F6R6e6ThtqG3J4OIbQXDz5YW7SykjouEVy2D2oIOzyPvFlJO"
              title="Lega Pauper - Spring Season"
              descr="Tutte le liste e le info sulla prima stagione delle Lega Pauper Livorno."
              image={getRandomImage()}
              tags={["Lega", "1° Stagione"]}
              date="12 Giu 2023"
            />
          </div>
        </div>
      </div>
    </div>
  );
};