import React from "react";
import { League } from "./league";
import { Tournament } from "./tournament";
import { useParams } from "react-router-dom";

export const Event = () => {
  const { type, id } = useParams();

  const renderUpdater = () => {
    switch (type) {
      case "0":
        return <Tournament id={id} />;
      case "1":
        return <League id={id} />;
      default:
        return <div>Invalid type</div>; // Handle the default case
    }
  };

  return <>{renderUpdater()}</>; // Ensure the result is rendered as JSX
};
